<template>
  <div class="dialog-test-page">
    <div class="page-header">
      <h1>Dialog Components</h1>
      <p class="subtitle">Test and preview all dialog variants with different states and configurations</p>
    </div>

    <div class="test-sections">
      <!-- User Flow Dialogs -->
      <div class="section">
        <h2>User Flow Dialogs</h2>
        <div class="button-grid">
          <div class="test-card">
            <div class="card-content">
              <h3>Artist Verification</h3>
              <p>Test the artist claim and verification flow</p>
              <div class="actions">
                <button @click="showAreYouArtistDialog = true" class="primary_btn small">
                  Are You This Artist?
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Authentication</h3>
              <p>Test login and authentication related dialogs</p>
              <div class="actions">
                <button @click="showNeedLoginDialog = true" class="primary_btn small">
                  Need Login
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Payment Flow</h3>
              <p>Test payment and transaction related dialogs</p>
              <div class="actions">
                <button @click="showArtistReceivingPaymentDialog = true" class="primary_btn small">
                  Artist Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Content Management -->
      <div class="section">
        <h2>Content Management</h2>
        <div class="button-grid">
          <div class="test-card">
            <div class="card-content">
              <h3>Downloads</h3>
              <p>Test download related dialogs</p>
              <div class="actions">
                <button @click="showDownloadTrackDialog = true" class="primary_btn small">
                  Download Track
                </button>
                <button @click="showDownloadAlbumDialog = true" class="primary_btn small">
                  Download Album
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Album Management</h3>
              <p>Test album modification dialogs</p>
              <div class="actions">
                <button @click="showWarningChangeAlbumDialog = true" class="primary_btn small">
                  Change Album
                </button>
                <button @click="showSetAlbumPriceDialog = true" class="primary_btn small">
                  Set Price
                </button>
                <button @click="showDeleteReleaseDialog = true" class="primary_btn small">
                  Delete Release
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Account Management</h3>
              <p>Test account-related dialogs</p>
              <div class="actions">
                <button @click="showDeleteAccountDialog = true" class="primary_btn small">
                  Delete Account
                </button>
                <button @click="showChangeEmailDialog = true" class="primary_btn small">
                  Change Email
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Communication</h3>
              <p>Test communication and support dialogs</p>
              <div class="actions">
                <button @click="showContactDialog = true" class="primary_btn small">
                  Contact Form
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Free Downloads Section -->
      <div class="section">
        <h2>Free Downloads</h2>
        <div class="button-grid">
          <div class="test-card highlight-card">
            <div class="card-content">
              <h3>Free Downloads Dialogs</h3>
              <p>Test the free downloads dialogs with different states and user permissions</p>
              <div class="actions">
                <button @click="showDownloadTrackDialog = true" class="c2a_btn small">
                  Track Downloads
                </button>
                <button @click="showDownloadAlbumDialog = true" class="c2a_btn small">
                  Album Downloads
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- System Dialogs -->
      <div class="section">
        <h2>System Dialogs</h2>
        <div class="button-grid">
          <div class="test-card">
            <div class="card-content">
              <h3>Notifications</h3>
              <p>Test system notification dialogs</p>
              <div class="actions">
                <button @click="showErrorDialog = true" class="primary_btn small">
                  Error Dialog
                </button>
                <button @click="showComingSoonDialog = true" class="primary_btn small">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Device Specific</h3>
              <p>Test device-specific dialogs</p>
              <div class="actions">
                <button @click="showAttentionMobileDialog = true" class="primary_btn small">
                  Mobile Version
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>System Status</h3>
              <p>Test system status notifications</p>
              <div class="actions">
                <button @click="showConnectionLost()" class="primary_btn small">
                  Connection Lost
                </button>
                <button @click="showStopPlayDialog = true" class="primary_btn small">
                  Stop Playback
                </button>
              </div>
            </div>
          </div>

          <div class="test-card">
            <div class="card-content">
              <h3>Payment</h3>
              <p>Test payment processing dialogs</p>
              <div class="actions">
                <button @click="showStripePaymentDialog = true" class="primary_btn small">
                  Stripe Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog Components -->
    <are-you-this-artist-dialog 
      v-if="showAreYouArtistDialog" 
      v-model="showAreYouArtistDialog"
      :artist="mockArtist"
    />

    <error-dialog 
      v-if="showErrorDialog" 
      v-model="showErrorDialog"
    />

    <artist-receiving-payment-dialog 
      v-if="showArtistReceivingPaymentDialog" 
      v-model="showArtistReceivingPaymentDialog"
    />

    <coming-soon-dialog 
      v-if="showComingSoonDialog" 
      v-model="showComingSoonDialog"
    />

    <download-track-dialog 
      v-if="showDownloadTrackDialog" 
      v-model="showDownloadTrackDialog"
    />

    <download-album-dialog 
      v-if="showDownloadAlbumDialog" 
      v-model="showDownloadAlbumDialog"
    />

    <warning-change-album-dialog 
      v-if="showWarningChangeAlbumDialog" 
      v-model="showWarningChangeAlbumDialog"
      :album-i-d="mockAlbum.id"
    />

    <attention-mobile-version-dialog 
      v-if="showAttentionMobileDialog" 
      v-model="showAttentionMobileDialog"
    />

    <need-login-dialog 
      v-if="showNeedLoginDialog" 
      v-model="showNeedLoginDialog"
    />

    <!-- Additional Dialog Components -->
    <delete-account-dialog
      v-if="showDeleteAccountDialog"
      v-model="showDeleteAccountDialog"
    />

    <change-email-dialog
      v-if="showChangeEmailDialog"
      v-model="showChangeEmailDialog"
    />

    <delete-release-dialog
      v-if="showDeleteReleaseDialog"
      v-model="showDeleteReleaseDialog"
      :delete-release="mockRelease"
    />

    <set-album-price-dialog
      v-if="showSetAlbumPriceDialog"
      v-model="showSetAlbumPriceDialog"
      :album="mockAlbum"
      :type="'music_release'"
      :set-album-your-price-modal-variant="'set'"
    />

    <contact-dialog
      v-if="showContactDialog"
      v-model="showContactDialog"
    />

    <connection-interrupted-dialog
      v-if="showConnectionInterruptedDialog"
      v-model="connectionStatus"
    />

    <stop-play-dialog
      v-if="showStopPlayDialog"
      v-model="showStopPlayDialog"
    />

    <stripe-payment-dialog
      v-if="showStripePaymentDialog"
      v-model="showStripePaymentDialog"
      :client-secret="mockStripeData.clientSecret"
      :payment-person="mockStripeData.paymentPerson"
    />
  </div>
</template>

<script>
import AreYouThisArtistDialog from '@/components/dialogs/areYouThisArtist.vue'
import ErrorDialog from '@/components/dialogs/error.vue'
import ArtistReceivingPaymentDialog from '@/components/dialogs/artistReceivingPayment.vue'
import ComingSoonDialog from '@/components/dialogs/comingSoonDialog.vue'
import DownloadTrackDialog from '@/components/dialogs/downloadTrack.vue'
import DownloadAlbumDialog from '@/components/dialogs/downloadAlbum.vue'
import WarningChangeAlbumDialog from '@/components/dialogs/warningСhangeAlbum.vue'
import AttentionMobileVersionDialog from '@/components/dialogs/attentionMobileVersion.vue'
import NeedLoginDialog from '@/components/dialogs/needLogin.vue'
import DeleteAccountDialog from '@/components/dialogs/deleteAccount.vue'
import ChangeEmailDialog from '@/components/dialogs/changeEmail.vue'
import DeleteReleaseDialog from '@/components/dialogs/deleteRelease.vue'
import SetAlbumPriceDialog from '@/components/dialogs/setYouAlbumPrice.vue'
import ContactDialog from '@/components/dialogs/contactDialog.vue'
import ConnectionInterruptedDialog from '@/components/dialogs/connectionInterruptedTimer.vue'
import StopPlayDialog from '@/components/dialogs/thisActionStopPlay.vue'
import StripePaymentDialog from '@/components/dialogs/paymentStripeModal.vue'

export default {
  name: 'DialogTest',
  
  components: {
    AreYouThisArtistDialog,
    ErrorDialog,
    ArtistReceivingPaymentDialog,
    ComingSoonDialog,
    DownloadTrackDialog,
    DownloadAlbumDialog,
    WarningChangeAlbumDialog,
    AttentionMobileVersionDialog,
    NeedLoginDialog,
    DeleteAccountDialog,
    ChangeEmailDialog,
    DeleteReleaseDialog,
    SetAlbumPriceDialog,
    ContactDialog,
    ConnectionInterruptedDialog,
    StopPlayDialog,
    StripePaymentDialog
  },

  data() {
    return {
      showAreYouArtistDialog: false,
      showErrorDialog: false,
      showArtistReceivingPaymentDialog: false,
      showComingSoonDialog: false,
      showDownloadTrackDialog: false,
      showDownloadAlbumDialog: false,
      showWarningChangeAlbumDialog: false,
      showAttentionMobileDialog: false,
      showNeedLoginDialog: false,
      showDeleteAccountDialog: false,
      showChangeEmailDialog: false,
      showDeleteReleaseDialog: false,
      showSetAlbumPriceDialog: false,
      showContactDialog: false,
      showConnectionInterruptedDialog: false,
      showStopPlayDialog: false,
      showStripePaymentDialog: false,

      // Mock data for dialogs
      mockArtist: {
        id: 1,
        name: 'Test Artist'
      },
      mockRelease: {
        id: '1',
        title: 'Test Release',
        smallImage: null,
        artist: {
          id: 1,
          name: 'Test Artist'
        }
      },
      mockAlbum: {
        id: '1',
        title: 'Test Album',
        price: 9.99,
        smallImage: null,
        artist: {
          id: 1,
          name: 'Test Artist'
        }
      },
      mockStripeData: {
        clientSecret: 'test_secret_key',
        paymentPerson: {
          id: 1,
          name: 'Test Artist',
          '@type': 'Artist',
          avatar: null
        }
      },
      connectionStatus: true
    }
  },
  
  methods: {
    showConnectionLost() {
      this.connectionStatus = false;
      this.showConnectionInterruptedDialog = true;
    },
    
    // Mock Vuex actions for download dialogs
    GET_DOWNLOAD_SONG_LINK() {
      return Promise.resolve();
    },
    GET_DOWNLOAD_MUSIC_RELEASE_LINK() {
      return Promise.resolve();
    }
  },
  
  computed: {
    // Mock Vuex getters for download dialogs
    SONG_DOWNLOADED() {
      return {
        limit: 5,
        downloaded: 2
      };
    },
    SONG_DOWNLOAD_DATA() {
      return {
        id: '1',
        artist: 'Test Artist',
        name: 'Test Track'
      };
    },
    DOWNLOAD_SONG_LINK() {
      return '#';
    },
    DOWNLOAD_SONG_LINK_ERROR() {
      return '';
    },
    PROFILE() {
      return 'free';
    },
    CHECK_MUSIC_RELEASE_DOWNLOAD() {
      return {
        limit: 5,
        downloaded: 2
      };
    },
    MUSIC_RELEASE_DOWNLOAD_DATA() {
      return {
        id: '1',
        title: 'Test Album'
      };
    },
    DOWNLOAD_MUSIC_RELEASE_LINK() {
      return '#';
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-test-page {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  .page-header {
    margin-bottom: 3rem;
    text-align: center;

    h1 {
      color: #fff;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
    }
  }

  .section {
    margin-bottom: 3rem;

    h2 {
      color: #fff;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(95, 105, 134, 0.3);
    }

    .button-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;
    }
  }

  .test-card {
    background: #282C47;
    border: 1px solid #353B49;
    border-radius: 12px;
    padding: 1.5rem;
    height: 100%;

    .card-content {
      h3 {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      p {
        color: rgba(255, 255, 255, 0.7);
        font-size: 0.9rem;
        line-height: 1.4;
        margin-bottom: 1rem;
      }

      .actions {
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;

        button {
          min-width: 120px;
        }
      }
    }

    &.highlight-card {
      background: linear-gradient(145deg, #282C47, #353B5E);
      border: 1px solid #5F6986;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(74.68deg, #FF0099 -12.21%, #20A8F5 112.21%);
      }
    }
  }
}
</style> 